div.env-info {
    position: fixed;
    left: 50%;
    top: 0;
    font-size: 15px;
    padding: 3px;
    font-weight: bold;
    background: yellow;
    z-index: 10000;
}
