.bg-nonhome {
    min-height: 100px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 70px;
}

.powered-by {
    font-weight: bold;
    color: white;
    margin-right: 1em;
}
