$smileSize: 40px;

.food-consistency .irs-grid-text {
    font-weight: normal;
    font-size: 8pt;
}

.irs-value-name .irs-single {
    font-weight: normal;
    font-size: 11pt;
}

.mood {
    background: url("./assets/images/slider/mood-bg.png") center 30px no-repeat;
    background-size: 1px 20px;

    &.irs-no-value {
        .irs-single {
            filter: grayscale(100%);
        }
    }

    .irs-handle {
        border-radius: 24px 24px 0 24px;
    }

    .irs-single {
        z-index: 9;
        top: -15px;
        width: $smileSize;
        height: $smileSize;
        color: transparent !important;
        background: url("./assets/images/slider/smile-sprite.png");
        background-repeat: no-repeat;
        background-position-x: 0 left;
    }

    @for $i from 0 through 4 {
        &.smile#{5 - $i} .irs-single {
            background-position-y: -$i * $smileSize;
        }

        .irs-grid,
        .irs-grid-text {
            visibility: hidden !important;
        }
    }
}
