.chart-groups {
    .chart-group {
        // float: left;
        // width: 100%;
        // position: relative;

        .loading {
            position: absolute;
            left: 50%;
            top: 50%;
        }

        &__header {
            z-index: 1;
            position: relative;
            float: left;
            width: 100%;
            padding: 10px 20px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
            font-size: 20px;

            @media (max-width: 991px) {
                padding-bottom: 55px;
            }

            @media (max-width: 400px) {
                padding: 10px 10px 55px 10px;
            }

            .react-datepicker-wrapper {
                width: auto;
            }

            .react-datepicker__tab-loop {
                display: inline-block;
            }

            &-action {
                float: right;

                // @media (max-width: 400px) {
                //     transform: none;
                //     left: 21px;
                //     top: 50px;
                //     position: absolute;
                // }

                i {
                    float: left;
                    margin-left: 20px;
                    line-height: 32px;
                    font-size: 28px;
                    cursor: pointer;
                    color: #12b2a2;
                    position: relative;

                    @media (max-width: 360px) {
                        margin-left: 15px;
                    }

                    span {
                        position: absolute;
                        left: -10px;
                        bottom: -3px;
                        background: #12b2a2;
                        color: #fff;
                        min-width: 20px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        font-size: 11px;
                        font-weight: bold;
                        border-radius: 10px;
                    }

                    span.not-read {
                        background: red;
                    }
                }
                &__messages {
                    i.patient-preview {
                        color: #0045b5;

                        span {
                            background: #0045b5;
                        }
                        span.not-read {
                            background: red;
                        }
                    }
                }
            }

            &-dates {
                position: absolute;
                top: 10px;
                left: 50%;
                transform: translateX(-50%);

                @media (max-width: 991px) {
                    transform: none;
                    left: 17px;
                    top: 50px;
                }

                @media (max-width: 400px) {
                    left: 0;
                    transform: none;
                    width: 100%;
                    top: 60px;
                    text-align: center;
                    input {
                        width: 80px !important;
                        padding-left: 5px;
                        padding-right: 0px;
                    }
                }

                &-dateText {
                    font-size: 0.875rem;
                }

                span,
                select,
                input {
                    //float: left;
                    margin: 0 2.5px;
                    height: 32px;
                    line-height: 32px;
                }

                i {
                    //width: 32px;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 5px;
                    color: #fff;
                    background: #12b2a2;
                    cursor: pointer;
                    padding-left: 5px;
                    padding-right: 5px;

                    span {
                        font-size: 10px;
                        text-transform: uppercase;
                        font-weight: 600;
                        position: relative;
                        bottom: -1px;
                    }
                }

                input {
                    //padding: 0 10px;
                    padding-bottom: 8px;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    width: 100px;

                    &:focus {
                        outline: none;
                        border-color: #12b2a2;
                    }
                }
            }

            &-dates2 {
                // position: absolute;
                // top: 10px;
                // left: 50%;
                // transform: translateX(-50%);
                float: left;
                display: flex;
                flex-wrap: wrap;
                width: calc(100% - 30px);

                @media (max-width: 991px) {
                    transform: none;
                    left: 17px;
                    top: 50px;
                }

                @media (max-width: 400px) {
                    left: 6px;

                    input {
                        width: 80px !important;
                        padding-left: 5px;
                        padding-right: 0px;
                    }
                }

                &-dateText {
                    font-size: 0.875rem;
                }

                span,
                select,
                input {
                    //float: left;
                    margin: 0 2.5px;
                    height: 32px;
                    line-height: 32px;
                }

                select {
                    padding: 5px 5px;
                    width: auto;
                }

                i {
                    //width: 32px;
                    line-height: 32px;
                    text-align: center;
                    border-radius: 5px;
                    color: #fff;
                    background: #12b2a2;
                    cursor: pointer;
                    padding-left: 5px;
                    padding-right: 5px;

                    span {
                        font-size: 10px;
                        text-transform: uppercase;
                        font-weight: 600;
                        position: relative;
                        bottom: -1px;
                    }
                }

                input {
                    //padding: 0 10px;
                    padding-bottom: 8px;
                    appearance: none;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    width: 100px;

                    &:focus {
                        outline: none;
                        border-color: #12b2a2;
                    }

                    &[type="time"] {
                        padding: initial;
                        text-align: center;
                    }
                }
            }
        }

        .charts {
            //background-color: #fff;
            width: 100%;
            float: left;
            padding-left: 0px;
            padding-right: 0px;
            //margin-left: 1%;

            @media (max-width: 450px) {
                padding-bottom: 25px;
            }

            .spo2 {
                text-align: center;
                font-size: 0.7rem;
            }
        }

        .chart {
            @media (max-width: 450px) {
                padding: 0;
            }
        }

        .remove-group {
            padding-right: 10px;
            padding-top: 10px;
            text-align: right;
        }
    }

    .add-group {
        padding-right: 10px;
        text-align: right;
    }
}
