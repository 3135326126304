.popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}

.popover-title-week {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
}

.popover-content {
    padding: 8px 14px;
    font-size: 14px;
}

.calorics-table {
    font-size: 12px;
    .kt-units {
        display: inline-block;
    }

    .total-weeks {
        font-size: 15px !important;
    }

    .total-label {
        padding-bottom: 10px;
    }

    .badge-number {
        width: 45px !important;
    }

    .badge.structure {
        cursor: help;
    }

    .badge {
        display: inline-block;
        min-width: 10px;
        padding: 0.25em 0.4em;
        font-size: 100%;
        font-weight: bold;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-color: #777;
        border-radius: 10px;
    }

    .table {
        margin-bottom: 16px;
    }

    .table > thead > tr > th {
        border-bottom: 2px solid #dee2e6;
    }
    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        border-top: 1px solid #dee2e6;
        padding: 2px 5px;
        font-size: 12px;
    }

    .table,
    #comments-box div.comments {
        display: none;
    }

    .food-box table.open,
    #comments-box div.open {
        display: table;
    }

    .food-box table.open > thead > tr > th {
        border-top: 0px !important;
    }

    #structure {
        float: right;
        margin: 15px 15px 0 0;
    }

    #structure table {
        display: block;
    }

    #structure table th {
        border: 0;
    }

    #structure table td {
        text-align: right;
        border: 0;
    }

    .total-info {
        padding: 15px 0 0 15px;
        float: left;
    }

    .total-kj,
    .total-kj-expediture,
    .total-steps {
        font-size: 36px;
        font-weight: 100;
        line-height: 40px;
        display: inline-block;
    }

    .total-kj2 {
        font-size: 16px;
        font-weight: 100;
        display: inline-block;
        padding-left: 5px;
        padding-right: 3px;
    }

    .total-label,
    .total-label-steps {
        color: #ccc;
        font-size: 12px;
        margin-top: -4px;
        display: block;
    }

    .main-dish {
        background-color: rgba(18, 178, 162, 0.05);
    }
    .text-align-right {
        text-align: right;
    }
    .text-align-left {
        text-align: left;
    }

    .panel-body {
        text-align: left;
        font-weight: bolder;
        text-transform: uppercase;
    }
}

.popup-content {
    margin-left: -5px;
    background: rgb(255, 255, 255);
    border-radius: 6px;
    //padding: 15px;
}
.popup-arrow {
    color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
    width: auto;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 30px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
    background: transparent;
}
