$primary-fg-color: white;
$primary-bg-color: #12b2a2;
$primary-bg-hover-color: lighten($primary-bg-color, 10%);

$secondary-bg-color: #b21222;

$text-color: #24373d;
$body-bg-color: #fff;

$header-text-color: $primary-fg-color;
$header-bg-color: rgba($text-color, 0.6);
$footer-bg-color: $text-color;

$validation-color: #f00;
$validation-hover-color: lighten($validation-color, 20%);

$radius: 5px;
$logo-width: 40px;

$shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
$radius: 5px;

$maincolor: $primary-bg-color;
$mainradius: $radius;

.messages {
    &__old-messages {
        font-family: Arial, sans-serif;
        line-height: normal;
        font-size: 13px;
        margin-bottom: 5px;
        color: rgba(0, 0, 0, 0.9);

        .messages-overflow {
            overflow: auto;
            //max-height: 602px; nastavuje se dynamicky javascriptem
        }

        &-container {
            //float: left;
            border-radius: $mainradius;
            width: 100%;
            //overflow: hidden;
            //position: relative;
            //z-index: 1;
            background: #fff;
        }
        &-header {
            float: left;
            width: 100%;
            box-shadow: $shadow;
            padding: 15px;
            span {
                float: left;
                font-size: 14px;
                font-weight: bold;
                color: #888;
            }
            i {
                float: right;
                font-size: 20px;
                color: $maincolor;
                cursor: pointer;
            }
        }
        &-body {
            width: 100%;
            padding: 10px;
            overflow: auto;
            display: flex;
            flex-direction: column;
        }
        &-dateitem {
            float: left;
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: rgb(107, 107, 107);
            margin-top: 10px;
            margin-bottom: 0px;
            // &.first {
            //     margin-top: auto;
            // }
            &--empty {
                margin: 0;
            }
        }
        &-fullname {
            padding-left: 44px;
            font-size: 12px;
            color: rgb(107, 107, 107);
        }
        &-circle {
            font-size: 14px;
            position: relative;
            float: left;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            margin-right: 8px;
            background: rgb(215, 250, 239);
            color: rgb(18, 189, 132);
            border-radius: 16px;
            font-weight: bold;

            &.profi {
                color: #0045b5;
                background: rgba(#0045b5, 0.2);
            }
            & + .messages__old-messages-message {
                max-width: calc(100% - 40px);
            }
            .messages__old-messages-tooltip {
                position: absolute;
                visibility: hidden;
                opacity: 0;
                left: -5px;
                bottom: calc(100% - 10px);
                transition: all 0.4s ease;
                background: rgba(0, 0, 0, 0.5);
                padding: 0 6px;
                border-radius: $mainradius;
                white-space: nowrap;
                line-height: 22px;
                font-weight: normal;
                color: #fff;
                &::after {
                    content: "";
                    position: absolute;
                    left: 13px;
                    bottom: -7px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 7px 0 7px;
                    border-color: rgba(0, 0, 0, 0.5) transparent transparent transparent;
                    z-index: 0;
                }
            }
            &:hover {
                .messages__old-messages-tooltip {
                    visibility: visible;
                    opacity: 1;
                    bottom: calc(100% + 10px);
                }
            }
        }
        &-itemcontainer {
            float: left;
            width: 100%;
        }
        &-item {
            float: left;
            width: 100%;
            margin-top: 5px;
            &:first-of-type {
                margin-top: 0;
            }
            &--recipient {
                padding-right: 10px;
                & + .messages__old-messages-item--recipient {
                    margin-top: 5px;
                }
                & + .messages__old-messages-item--sender {
                    margin-top: 15px;
                }
                .messages__old-messages-message {
                    float: left;
                    background: #eee;
                }
            }
            &--sender {
                padding-left: 10px;
                & + .messages__old-messages-item--sender {
                    margin-top: 5px;
                }
                & + .messages__old-messages-item--recipient {
                    margin-top: 15px;
                }
                .messages__old-messages-message {
                    float: right;
                    background: rgba($maincolor, 0.2);
                    &.profi {
                        background: rgba(#0045b5, 0.2);
                    }
                }
            }
        }
        &-message {
            padding: 5px;
            width: auto;
            border-radius: $mainradius;

            .dates-info {
                font-size: smaller;
                margin-top: 2px;

                .creation-date {
                    float: left;
                    i:before {
                        font-size: smaller;
                    }
                }

                .displayed-date {
                    float: right;
                }

                .creation-date,
                .displayed-date {
                    color: rgb(107, 107, 107);
                    font-size: 11px;
                }

                .displayed-date-button {
                    background: none;
                    border: none;
                    padding: 0 0 0 10px;

                    &.profi {
                        color: #0045b5;
                        &:hover {
                            color: red;
                        }
                    }

                    &.normal {
                        color: $maincolor;
                        &:hover {
                            color: red;
                        }
                    }
                }
            }
        }
        &-footer {
            float: left;
            width: 100%;
            box-shadow: $shadow;
            padding: 15px;
            textarea {
                float: left;
                height: 40px;
                width: calc(100% - 45px);
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                border: 1px solid #ddd;
                border-radius: $mainradius;
                padding: 5px;
                &:focus {
                    border-color: $maincolor;
                    outline: none;
                }
            }
            i {
                float: left;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: $maincolor;
                color: #fff;
                margin-left: 5px;
                border-radius: $mainradius;
                cursor: pointer;
            }
        }
    }
    .conversation-list.prof-list {
        // pozor pokud se bude měnit "max-height: 660px;", tak nutno upravit i Communications.js funkci
        // updateOverflowMaxHeight kde se nastavuje max výška pro zprávy na drsňáka 630px viz opicatskaKonstanta
        max-height: 660px;
    }
    .conversation-list {
        overflow: auto;
        max-height: 460px;
        // pozor pokud se bude měnit "max-height: 660px;", tak nutno upravit i Communications.js funkci
        // updateOverflowMaxHeight kde se nastavuje max výška pro zprávy na drsňáka 430px viz opicatskaKonstanta
        color: rgba(0, 0, 0, 0.9);
        div.conversation.normal.selected {
            background-color: #12b2a2;
        }
        div.conversation.profi.selected {
            background-color: #005aeb;
            color: white;
        }
        div.conversation.normal:hover {
            background-color: #6ab1aa;
        }
        div.conversation.profi:hover {
            background-color: #528ae4;
            color: white;
        }
        .conversation {
            cursor: pointer;
            padding: 0.2rem 1rem;

            &:first-child {
                border-radius: 5px 5px 0 0;
            }
            &:last-child {
                border-radius: 0 0 5px 5px;
            }
            &:first-child:last-child {
                border-radius: 5px;
            }
            &.c-comments {
                background-color: #8aadb8;
            }
            &.c-messages {
                background-color: #b2bec2;
            }
            .messages-count {
                font-weight: bold;
            }
            .messages-comments-count {
                font-size: smaller;
            }
            .not-read-count {
                font-weight: bold;
                &.unread {
                    color: red;
                    padding: 4px 8px;
                    font-size: small;

                    @media (max-width: 1200px) {
                        padding: 0;
                    }
                }
            }

            .conversation-state {
                border-radius: 20px;

                background-color: #d5e1e6;
                min-width: 26px;
                height: 26px;
                text-align: center;
                display: inline-block;

                i::before {
                    display: inline-block;
                    margin: 6px 4px;
                }

                i.all-read::before {
                    color: #12b2a2;
                }
                i.profi-all-read::before {
                    color: #005aeb;
                }
            }
        }
    }
}
