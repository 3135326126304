@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "../MedilogVariables";

.medilog {
    .home-header {
        background: url(../../../assets/images/frontpage-cover.jpg) top center no-repeat;
        background-size: cover;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }

        .content {
            position: relative;
            z-index: 1;
            padding: 180px 0 60px;

            @include media-breakpoint-down(md) {
                padding: 130px 0 60px;
            }
        }
    }

    .customer-type-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5rem;

        @include media-breakpoint-down(xs) {
            justify-content: center;
        }

        @include media-breakpoint-down(md) {
            margin-top: 2rem;
        }

        li {
            margin: 0 2rem 0 0;

            @include media-breakpoint-down(md) {
                margin: 0 1rem 0.5rem 0;
            }
        }

        a {
            display: flex;
            align-items: center;
            flex-flow: column;
            color: #fff;
            text-decoration: none;
            cursor: pointer;

            &:hover {
                .customer-type-list__icon {
                    background-color: rgb(224, 216, 212);
                }

                color: #fff;
            }
        }

        &__icon {
            width: 80px;
            height: 80px;
            border-radius: 30px 5px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
            background-color: #fff;
            margin-bottom: 0.75rem;
            color: red;
            font-size: 45px;
            position: relative;

            @include media-breakpoint-down(sm) {
                width: 55px;
                height: 55px;
                font-size: 28px;
            }

            @include media-breakpoint-between(sm, md) {
                width: 60px;
                height: 60px;
                font-size: 30px;
            }

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &__label {
            text-align: center;
            line-height: 1.25;

            @include media-breakpoint-down(md) {
                font-size: 13px;
            }
        }
    }
}
