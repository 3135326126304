@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "HhVariables";

.hh {
    @import "../../scss/modules/project";

    .univ__inbody:first-of-type {
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
            margin-top: 4rem;
        }
    }

    .univ__inbody--bgdark {
    }

    .univ__inbody--bglight {
    }

    .chart-group__header {
        box-shadow: none;
    }

    @import "../../scss/shared/Messages";
}
