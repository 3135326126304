.ecg {
    &__button {
        color: #12b2a2;
        background: none;
        border: none;

        &:hover {
            color: #12b2a2;
        }
    }
}

.ecg-table {
    font-size: 12px;
}
