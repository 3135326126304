@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "PlusCareVariables";

.pluscare {
    $bgUrl: "";

    @import "../../scss/modules/project";

    .univ__inbody:first-of-type {
        margin-top: 0;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }
    }

    .univ__inbody--bgdark {
    }

    .univ__inbody--bglight {
    }

    .home-header {
        .logincard {
            margin-top: 100px;
        }
    }

    .navbar-brand {
        img {
            width: 160px;
            margin-top: 0px;

            @include media-breakpoint-up(lg) {
                margin-top: 28px;
            }
        }
    }

    .chart-group__header {
        box-shadow: none;
    }

    @import "../../scss/shared/Messages";
}
