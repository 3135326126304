@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

body {
    color: $text-color;
    background: $body-bg-color;
}

.red,
.header--page .navbar-nav .nav-link,
.navbar-toggler,
.action-link:hover,
.action-link:focus,
.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single,
.hs-value,
.btn-link,
.blazored-modal-close,
.header--home .user a,
.header--page .user a,
.simulated-a {
    color: $primary-bg-color;
}

.header--page {
    border-bottom-color: $primary-bg-color;
    background: $body-bg-color;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.page-item.active .page-link {
    background-color: $primary-bg-color;
    border-color: $primary-bg-color;
}

.btn-primary:hover {
    background-color: lighten($primary-bg-color, 10%);
    border-color: lighten($primary-bg-color, 10%);
}

.btn-delete,
.btn-delete:not(:disabled):not(.disabled):active,
.btn-delete:not(:disabled):not(.disabled).active {
    background-color: #d32f2f;
    border-color: #d32f2f;
    text-decoration-color: white;
}

.btn-delete:hover {
    background-color: lighten(#d32f2f, 10%);
    border-color: lighten(#d32f2f, 10%);
}

.irs--round .irs-bar,
.hs-handle {
    background-color: $primary-bg-color;
}

.irs--round .irs-handle,
.hs-handle {
    border-color: $primary-bg-color;
}

.home-header {
    &::before {
        background-color: transparent;
    }

    &__title {
        font-size: 2em;
    }
    &__info {
        margin-top: 20px;

        li {
            font-size: 1.1em !important;
            margin-bottom: 1em;
        }
    }
}

@include media-breakpoint-down(sm) {
    // .header--page,
    .header--page .navbar-collapse {
        background: $primary-bg-color;
    }

    .header--home .navbar-collapse {
        background-color: $primary-bg-color;
    }
}

.powered-by {
    color: $primary-bg-color;
}

.btn-outline-light {
    color: $primary-bg-color;
    border-color: $primary-bg-color;
}

.generic {
    &__notifications {
        .notifications-bell {
            color: $primary-bg-color !important;
            &:hover {
                color: $primary-bg-hover-color !important;
            }
        }
    }
}
