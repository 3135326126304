﻿@media (max-width: 575px) {
    .card-body {
        padding: 0.75rem;
    }
}

html {
    scroll-behavior: smooth;
}

.container--dark {
    background: #eeeeee;
}

.box {
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    @media (max-width: 450px) {
        padding: 0;
        box-shadow: none;
    }
}

h2.box-title {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 22pt;
}
