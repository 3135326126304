.hs-container {
    position: relative;
    max-width: 100%;
    margin: 0px auto;
}

.hs-value {
    -webkit-appearance: none;
    -moz-appearance: none;

    position: absolute;
    top: 0px;
    left: calc(50% - 35px);
    width: 70px;
    height: 30px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-sizing: border-box;
    z-index: 3;
    text-align: center;
    color: #ff0000;
    font-size: 20px;
    font-weight: bold;
    padding: 2px;
}

.hs-value-hidden {
    display: none;
}

.hs-container input::-webkit-outer-spin-button,
.hs-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hs-container input[type="number"] {
    -moz-appearance: textfield;
}

.hs-unit {
    position: absolute;
    top: 4px;
    left: calc(50% + 42px);
    height: 30px;
    z-index: 1;
}

.hs-handle {
    position: absolute;
    top: 16px;
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    border: 4px solid #db3b00;
    background-color: #db3b00;
    border-radius: 0;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.4);
    transform: rotate(45deg);
    box-sizing: border-box;
    cursor: default;
    z-index: 1;
    pointer-events: none;
}

.hs-axis-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    -ms-overflow-style: none;
    padding: 35px 50% 0px 50%;
    background: url("images/bg.png") repeat-x left 35px;
    background-repeat: repeat-x;
    background-position-y: 35px;
}

.hs-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to right, white 0px, transparent 30px),
        linear-gradient(to left, white 0px, transparent 30px);
    background: -webkit-linear-gradient(left, white 0px, rgba(255, 255, 255, 0) 30px),
        -webkit-linear-gradient(right, white 0px, rgba(255, 255, 255, 0) 30px);
}

.hs-axis-container::-webkit-scrollbar {
    display: none;
}

.hs-axis {
    position: relative;
    display: inline-block;
    height: 40px;
    background: url("images/axis-border.png") top left no-repeat, url("images/axis-border.png") top right no-repeat,
        url("images/axis.png") repeat-x;
}

.hs-axis.hs-time {
    background: url("images/axis-border.png") top left no-repeat, url("images/axis-border.png") top right no-repeat,
        url("images/axis-time.png") repeat-x;
}

.hs-axis-value {
    position: absolute;
    color: #979797;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    width: 40px;
    top: 14px;
}

.hs-inactive-value {
    color: #ccc;
    background: #eee;
    border-color: #eee;
}

.hs-inactive .hs-handle {
    border-color: #eee;
    background: #eee;
}
