.react-datepicker-wrapper .react-datepicker__close-icon::after {
    background-color: grey;
}

.std-date-picker {
    width: 110px;
}

.std-datetime-picker {
    width: 170px;
}

.std-datetime-popper {
    width: 330px;
}

.react-datepicker-popper {
    z-index: 100000 !important;
}

.react-datepicker__current-month {
    display: none;
}

select.react-datepicker__month-select,
select.react-datepicker__year-select {
    font-weight: bold;
    background: transparent;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
    margin-top: 4px;
}

/*silnější selector (přidán div) aby fungovalo šrafování v kalendáři tam kde jsou poznámky ---- počátek*/
/*vykopírováno a upraveno z /node_modules/react-datepicker/dist/react-datepicker.css*/
.react-datepicker__day--highlighted-custom-2 {
    border-radius: 0.3rem;
    background: repeating-linear-gradient(45deg, transparent 3px, #5e5e5e 6px, transparent 9px);
}

//vlastní barva dle CPZ
.react-datepicker__day--highlighted-spo2 {
    border-radius: 0.3rem;
    background: linear-gradient(45deg, blue 0%, blue 14%, transparent 14%, transparent 86%, blue 86%, blue 100%);
    &:hover {
        background: linear-gradient(
            45deg,
            rgb(0, 0, 100) 0%,
            rgb(0, 0, 100) 14%,
            transparent 14%,
            transparent 86%,
            rgb(0, 0, 100) 86%,
            rgb(0, 0, 100) 100%
        );
    }
    &.react-datepicker__day--highlighted-custom-2 {
        background: linear-gradient(
            45deg,
            blue 0%,
            blue 14%,
            rgba(94, 94, 94, 1) 14%,
            rgba(94, 94, 94, 1) 17%,
            rgba(0, 0, 0, 0) 25%,
            rgba(94, 94, 94, 1) 33%,
            rgba(94, 94, 94, 1) 34%,
            rgba(0, 0, 0, 0) 42%,
            rgba(94, 94, 94, 1) 50%,
            rgba(94, 94, 94, 1) 51%,
            rgba(0, 0, 0, 0) 59%,
            rgba(94, 94, 94, 1) 67%,
            rgba(94, 94, 94, 1) 68%,
            rgba(0, 0, 0, 0) 76%,
            rgba(94, 94, 94, 1) 84%,
            rgba(94, 94, 94, 1) 86%,
            blue 86%,
            blue 100%
        );

        &:hover {
            background: linear-gradient(
                45deg,
                rgb(0, 0, 100) 0%,
                rgb(0, 0, 100) 14%,
                rgba(94, 94, 94, 1) 14%,
                rgba(94, 94, 94, 1) 17%,
                rgba(0, 0, 0, 0) 25%,
                rgba(94, 94, 94, 1) 33%,
                rgba(94, 94, 94, 1) 34%,
                rgba(0, 0, 0, 0) 42%,
                rgba(94, 94, 94, 1) 50%,
                rgba(94, 94, 94, 1) 51%,
                rgba(0, 0, 0, 0) 59%,
                rgba(94, 94, 94, 1) 67%,
                rgba(94, 94, 94, 1) 68%,
                rgba(0, 0, 0, 0) 76%,
                rgba(94, 94, 94, 1) 84%,
                rgba(94, 94, 94, 1) 86%,
                rgb(0, 0, 100) 86%,
                rgb(0, 0, 100) 100%
            );
        }
    }
}

//vlastní barva dle CPZ
.react-datepicker__day--highlighted-ecg {
    border-radius: 0.3rem;
    background: linear-gradient(45deg, red 0%, red 14%, transparent 14%, transparent 86%, red 86%, red 100%);
    &:hover {
        background: linear-gradient(
            45deg,
            rgb(100, 0, 0) 0%,
            rgb(100, 0, 0) 14%,
            transparent 14%,
            transparent 86%,
            rgb(100, 0, 0) 86%,
            rgb(100, 0, 0) 100%
        );
    }
    &.react-datepicker__day--highlighted-custom-2 {
        background: linear-gradient(
            45deg,
            red 0%,
            red 14%,
            rgba(94, 94, 94, 1) 14%,
            rgba(94, 94, 94, 1) 17%,
            rgba(0, 0, 0, 0) 25%,
            rgba(94, 94, 94, 1) 33%,
            rgba(94, 94, 94, 1) 34%,
            rgba(0, 0, 0, 0) 42%,
            rgba(94, 94, 94, 1) 50%,
            rgba(94, 94, 94, 1) 51%,
            rgba(0, 0, 0, 0) 59%,
            rgba(94, 94, 94, 1) 67%,
            rgba(94, 94, 94, 1) 68%,
            rgba(0, 0, 0, 0) 76%,
            rgba(94, 94, 94, 1) 84%,
            rgba(94, 94, 94, 1) 86%,
            red 86%,
            red 100%
        );

        &:hover {
            background: linear-gradient(
                45deg,
                rgb(100, 0, 0) 0%,
                rgb(100, 0, 0) 14%,
                rgba(94, 94, 94, 1) 14%,
                rgba(94, 94, 94, 1) 17%,
                rgba(0, 0, 0, 0) 25%,
                rgba(94, 94, 94, 1) 33%,
                rgba(94, 94, 94, 1) 34%,
                rgba(0, 0, 0, 0) 42%,
                rgba(94, 94, 94, 1) 50%,
                rgba(94, 94, 94, 1) 51%,
                rgba(0, 0, 0, 0) 59%,
                rgba(94, 94, 94, 1) 67%,
                rgba(94, 94, 94, 1) 68%,
                rgba(0, 0, 0, 0) 76%,
                rgba(94, 94, 94, 1) 84%,
                rgba(94, 94, 94, 1) 86%,
                rgb(100, 0, 0) 86%,
                rgb(100, 0, 0) 100%
            );
        }
    }
}

//vlastní barva dle CPZ
.react-datepicker__day--highlighted-ecgAndSpo2 {
    border-radius: 0.3rem;
    background: linear-gradient(45deg, blue 0%, blue 14%, transparent 14%, transparent 86%, red 86%, red 100%);
    &:hover {
        background: linear-gradient(
            45deg,
            rgb(0, 0, 100) 0%,
            rgb(0, 0, 100) 14%,
            transparent 14%,
            transparent 86%,
            rgb(100, 0, 0) 86%,
            rgb(100, 0, 0) 100%
        );
    }
    &.react-datepicker__day--highlighted-custom-2 {
        background: linear-gradient(
            45deg,
            blue 0%,
            blue 14%,
            rgba(94, 94, 94, 1) 14%,
            rgba(94, 94, 94, 1) 17%,
            rgba(0, 0, 0, 0) 25%,
            rgba(94, 94, 94, 1) 33%,
            rgba(94, 94, 94, 1) 34%,
            rgba(0, 0, 0, 0) 42%,
            rgba(94, 94, 94, 1) 50%,
            rgba(94, 94, 94, 1) 51%,
            rgba(0, 0, 0, 0) 59%,
            rgba(94, 94, 94, 1) 67%,
            rgba(94, 94, 94, 1) 68%,
            rgba(0, 0, 0, 0) 76%,
            rgba(94, 94, 94, 1) 84%,
            rgba(94, 94, 94, 1) 86%,
            red 86%,
            red 100%
        );

        &:hover {
            background: linear-gradient(
                45deg,
                rgb(0, 0, 100) 0%,
                rgb(0, 0, 100) 14%,
                rgba(94, 94, 94, 1) 14%,
                rgba(94, 94, 94, 1) 17%,
                rgba(0, 0, 0, 0) 25%,
                rgba(94, 94, 94, 1) 33%,
                rgba(94, 94, 94, 1) 34%,
                rgba(0, 0, 0, 0) 42%,
                rgba(94, 94, 94, 1) 50%,
                rgba(94, 94, 94, 1) 51%,
                rgba(0, 0, 0, 0) 59%,
                rgba(94, 94, 94, 1) 67%,
                rgba(94, 94, 94, 1) 68%,
                rgba(0, 0, 0, 0) 76%,
                rgba(94, 94, 94, 1) 84%,
                rgba(94, 94, 94, 1) 86%,
                rgb(100, 0, 0) 86%,
                rgb(100, 0, 0) 100%
            );
        }
    }
}

div {
    .react-datepicker__day:hover {
        background-color: #f0f0f0 !important;
    }

    //vlastní barva dle CPZ
    .react-datepicker__day--highlighted {
        background-color: #12b2a2 !important;
    }

    //vlastní barva dle CPZ
    .react-datepicker__day--highlighted:hover {
        background-color: #119e90 !important;
    }

    // .react-datepicker__day--highlighted-custom-1 {
    //     color: magenta;
    // }

    .react-datepicker__day--highlighted-custom-2 {
        color: black;
    }

    .react-datepicker__day--highlighted.react-datepicker__day--highlighted-custom-2 {
        color: white;
    }

    .react-datepicker__day--highlighted.react-datepicker__day--disabled.react-datepicker__day--highlighted-custom-2 {
        color: #ccc;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
        background-color: #216ba5 !important;
    }

    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover {
        background-color: #1d5d90 !important;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: #2a87d0 !important;
    }

    .react-datepicker__day--keyboard-selected:hover {
        background-color: #1d5d90 !important;
    }

    .react-datepicker__day--in-selecting-range {
        background-color: rgba(33, 107, 165, 0.5) !important;
    }

    .react-datepicker__month--selecting-range .react-datepicker__day--in-range {
        background-color: #977a7a !important;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
    }

    .react-datepicker__day--disabled:hover {
        background-color: transparent !important;
    }

    .react-datepicker--time-only .react-datepicker__triangle {
        left: 35px !important;
    }
}
/*silnější selector (přidán div) aby fungovalo šrafování v kalendáři tam kde jsou poznámky ---- konec*/
