$primary-fg-color: white;
$primary-bg-color: #e00814;
$primary-bg-hover-color: lighten($primary-bg-color, 10%);

$secondary-bg-color: #13e008;

$text-color: #4e4e4e;
$body-bg-color: white;

$header-text-color: $primary-bg-color;
$header-bg-color: rgba($primary-fg-color, 0.95);
$footer-bg-color: #212930;

$validation-color: #f00;
$validation-hover-color: lighten($validation-color, 20%);

$radius: 5px;
$logo-width: 45px;

$unread-color: #a612b2;
