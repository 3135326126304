.medilog {
    .bg-nonhome {
        background: url(../../assets/images/frontpage-cover.jpg) top center no-repeat;
        background-size: cover;
        min-height: 220px;

        &::before {
            content: "";
            position: absolute;
            z-index: 0;
            background-color: rgba(0, 0, 0, 0.5);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            max-height: 220px;
        }
    }

    .powered-by {
        font-weight: bold;
        color: white;
        margin-right: 1em;
    }
}
