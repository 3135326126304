legend {
    width: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.action-link {
    font-weight: bold;
    display: inline-block;
    margin-right: 1rem;
    line-height: 1.2;

    &--active {
        border-bottom: 2px solid;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        border-bottom: 2px solid;
        color: darken($color: $primary, $amount: 10);
    }
}

.inner-card {
    background: #0001;
    padding: 1rem;
    border-radius: 5px;
}

@keyframes fly {
    0% {
        transform: translateY(0) translateX(0);
    }

    25% {
        transform: translateY(-25px) translateX(25px);
    }

    75% {
        transform: translateY(25px) translateX(-25px);
    }

    100% {
        transform: translateY(0) translateX(0);
    }
}

@keyframes jet {
    0% {
        transform: translateY(0) translateX(0px);
    }

    25% {
        transform: translateY(-5px) translateX(15px);
    }

    75% {
        transform: translateY(25px) translateX(5px);
    }

    100% {
        transform: translateY(0) translateX(0px);
    }
}

@keyframes jet2 {
    0% {
        transform: translateY(0) translateX(0px);
    }

    25% {
        transform: translateY(-10px) translateX(10px);
    }

    75% {
        transform: translateY(20px) translateX(15px);
    }

    100% {
        transform: translateY(0) translateX(0px);
    }
}

@keyframes jet3 {
    0% {
        transform: translateY(0) translateX(0px);
    }

    25% {
        transform: translateY(-10px) translateX(10px);
    }

    75% {
        transform: translateY(30px) translateX(-5px);
    }

    100% {
        transform: translateY(0) translateX(0px);
    }
}

@keyframes hide {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.svg-container--animated {
    .plane {
        animation: fly 3s ease infinite;
    }

    .jet1 {
        animation: jet 3s ease infinite, hide 3s infinite;
    }

    .jet2 {
        animation: jet2 3s ease infinite, hide 3s infinite;
    }

    .jet3 {
        animation: jet3 3s ease infinite, hide 3s infinite;
    }
}

.dropdown-menu {
    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.nav-tabs {
    margin-bottom: 1rem;
}

.alert {
    font-size: 14px;
}

form.ssf {
    .spinner {
        display: none;
    }

    .loading {
        .spinner {
            display: unset;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

input,
textarea,
select {
    outline: none;
    box-shadow: none !important;
    border: 1px solid #ced4da;
}

.table-responsive table td,
.table-responsive table th {
    font-size: 0.875rem;
}

@media (max-width: 330px) {
    .mx-sm-0 {
        margin: 0 !important;
    }
}

@media (max-width: 575px) {
    .mx-sm-0 {
        margin: 0 !important;
    }
}

.btn {
    &--min-width38 {
        min-width: 38px;
    }
}

.popover-body {
    padding: 1rem;
}

.dropdown-item {
    @include media-breakpoint-down(sm) {
        padding: 0.25rem 0 0.25rem 1rem;
    }
}

.img-cnt img {
    max-width: 100%;
    height: auto;
}

div.add-parameter {
    cursor: pointer;
}

input[type="range"] {
    visibility: hidden;
}

.bg-white {
    background: #fff;
}

.min-h-330 {
    min-height: 330px;
}

button.number-value {
    font-weight: bold;
    height: 30px;
    margin: 1px;
    padding: 2px;
    min-width: 2.5em;
}

input.number-value {
    padding-left: 2px !important;
    padding-right: 2px !important;
    text-align: center !important;
    font-weight: bold;
    font-size: 17px;
    width: 4em;
    color: #f00;
}

input.number-value:focus {
    color: #f00;
}

input.number-value.nv-small {
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

button {
    touch-action: manipulation;
}

/* pro mobil bude (0;0) jinde*/
.position-unset-sm {
    @include media-breakpoint-down(sm) {
        position: unset;
    }
}

.red {
    color: #e40114;
}

.simulated-a {
    background: none !important;
    border: none;
    font-family: inherit;
    text-decoration: none;
    cursor: pointer;
    color: #ff0000;
}

.simulated-a.d-block {
    width: 100%;
    text-align: left;
}

.underline {
    text-decoration: underline;
}
.va-inherit {
    vertical-align: inherit;
}

@media only screen and (max-width: 1200px) {
    .no-more-tables table {
        background: none;
    }

    /* Force table to not be like tables anymore */
    .no-more-tables table,
    .no-more-tables thead,
    .no-more-tables tbody,
    .no-more-tables th,
    .no-more-tables td,
    .no-more-tables tr {
        display: block;
    }
    /* Hide table headers (but not display: none;, for accessibility) */
    .no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .no-more-tables tr {
        background: #fff;
        border-top: 1px solid #ccc;
    }

    .no-more-tables td {
        /* Behave like a "row" */
        border: none;
        /*border-bottom: 1px solid #eee;*/
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align: left;
    }

    .no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 12px;
        left: 13px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
    }

    /* Label the data */
    .no-more-tables td:before {
        content: attr(data-title);
    }

    .no-more-tables td.d-md-pushleft {
        padding-left: 10px;
        float: left;
    }

    .no-more-tables td.d-md-pushright {
        padding-right: 10px;
        float: right;
    }
}

.shadow1 {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(97, 97, 97, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(97, 97, 97, 1);
    box-shadow: 0px 0px 10px 0px rgba(97, 97, 97, 1);
}

.border1 {
    border: 4px solid white;
}

.clearfix {
    clear: both;
}

.clearfix td {
    min-height: 45px;
}

button:disabled {
    opacity: 0.2 !important;
}

button:disabled:hover {
    opacity: 0.2 !important;
}
