.messages {
    &__old-messages {
        &-header {
            i {
                color: $primary-bg-color;
            }
        }
        &-circle {
            background: rgba($primary-bg-color, 0.2);
            color: rgba($primary-bg-color, 0.6);
        }

        &-item {
            &--recipient {
                .messages__old-messages-message {
                    background: #eee;
                }
            }
            &--sender {
                .messages__old-messages-message {
                    background: rgba($primary-bg-color, 0.2);
                }
            }
        }
        &-message {
            .dates-info {
                .creation-date,
                .displayed-date {
                    color: rgb(107, 107, 107);
                    font-size: 11px;
                }

                .displayed-date-button {
                    &.normal {
                        color: $primary-bg-color;
                        &:hover {
                            color: $unread-color;
                        }
                    }
                }
            }
        }
    }

    .conversation-list {
        color: rgba(0, 0, 0, 0.9);
        div.conversation.normal.selected {
            background-color: rgba($primary-bg-color, 0.3);
        }
        div.conversation.normal:hover {
            background-color: rgba($primary-bg-color, 0.2);
        }
        .conversation {
            &.c-comments {
                background-color: #8aadb8;
            }
            &.c-messages {
                background-color: #e3e3e3;
            }

            .not-read-count {
                &.unread {
                    color: $unread-color;
                }
            }

            .conversation-state {
                background-color: #f9f9f9;

                i.all-read::before {
                    color: #881d1da1;
                }
            }
        }
    }
}

.nav-link-counter {
    background: $unread-color;
}
