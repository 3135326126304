@import "../PlusCareVariables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.pluscare {
    .home-page {
        padding-top: 100px;
        background: white;
    }

    .hero-icons {
        display: block;
        float: left;
        width: 100%;
        text-align: center;
        padding-top: 20px;

        .icons-wrap {
            padding: 0;
            margin-bottom: 20px;

            img {
                height: 75px;
                width: auto;
                display: inline-block;
                border-radius: 5px;
            }

            span {
                display: inline-block;
                width: 100%;
                color: #000;
                font-weight: 600;
                font-size: 12px;
                margin-top: 15px;
            }
        }
    }

    .hero-box {
        background: rgba(255, 255, 255, 0.7);
        padding: 40px 40px;
        z-index: 1;
        width: 100%;
        border-radius: 5px;
    }

    .hero-headers h1 {
        font-size: 30px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 15px;
        margin-top: 0 !important;
    }

    .hero-headers p {
        color: #003059;
        font-size: 16px;
    }

    .icon {
        text-align: center;
        i {
            color: $primary-bg-color;
            font-size: 35px;
        }
        margin-bottom: 10px;
    }

    .panel {
        background: #fee;
        border-radius: 3px;
        box-shadow: 2px 2px 4px #ddd;
        padding: 20px 10px;
    }
}
