.header--home {
    .navbar {
        padding: 0;
        justify-content: flex-end;
        background-color: transparent !important;
        position: absolute;
        left: 0;
        right: 0;
        min-height: 80px;
        z-index: 7;

        @include media-breakpoint-down(md) {
            padding: 0 1rem;
        }
    }

    .navbar-brand {
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;

        @include media-breakpoint-down(md) {
            left: 1rem;
        }

        img {
            width: 181px;
            height: auto;

            @include media-breakpoint-down(md) {
                width: 100px;
            }
        }
    }

    .navbar-collapse {
        @include media-breakpoint-down(xs) {
            position: absolute;
            top: 60px;
            background: rgb(228, 1, 20);
            padding: 10px 15px 20px;
        }
    }

    .navbar-nav .nav-link {
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        padding: 0.5rem 0.5rem 0.25rem;
        margin: 0 0.75rem;
        border-bottom: 3px solid transparent;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            margin: 0 0.25rem;
        }

        &:hover {
            border-bottom: 3px solid;

            @include media-breakpoint-down(md) {
                border-bottom: 2px solid;
                color: #fff !important;
            }
        }
    }

    .nav-item {
        .nav-link {
            &.active {
                border-bottom: 3px solid;

                @include media-breakpoint-down(md) {
                    border-bottom: 2px solid;
                    color: #fff !important;
                }
            }
        }
    }
}

.header--page {
    @include media-breakpoint-down(sm) {
        background: rgb(228, 1, 20);
    }

    .navbar-container {
        position: relative;
    }

    .navbar {
        padding: 0.5rem 4rem 0;
        justify-content: flex-end;
        background-color: transparent !important;
        min-height: 80px;
        /*z-index: 999;*/

        @include media-breakpoint-down(sm) {
            min-height: 90px;
        }

        @include media-breakpoint-down(md) {
            padding: 0.5rem 1rem 0;
        }
    }

    .navbar-brand {
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;

        @include media-breakpoint-down(sm) {
            height: 83px;
            overflow: hidden;
        }

        @include media-breakpoint-down(md) {
            left: 0rem;
        }

        img {
            width: 107px;
            height: auto;

            @include media-breakpoint-up(lg) {
                width: 130px;
            }
        }
    }

    .navbar-collapse {
        @include media-breakpoint-down(sm) {
            position: absolute;
            top: 90px;
            right: -15px;
            background: rgb(228, 1, 20);
            padding: 15px 0px 20px;
            z-index: 10;
        }
    }

    .navbar-nav {
        margin-left: 0;

        @include media-breakpoint-up(sm) {
            margin-left: 10px;
        }

        @include media-breakpoint-up(lg) {
            margin-left: 100px;
        }
    }

    .navbar-nav .nav-link {
        color: rgb(228, 1, 20);
        font-size: 15px;
        font-weight: 600;
        padding: 0.5rem 0.5rem 0rem;
        margin: 0 0.75rem;
        border-bottom: 3px solid transparent;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            margin: 0 0.25rem;
        }

        @include media-breakpoint-down(sm) {
            color: #fff !important;
        }

        &:hover {
            border-bottom: 3px solid;

            @include media-breakpoint-down(md) {
                border-bottom: 2px solid;
            }
        }
    }

    .nav-item {
        .nav-link {
            &.active {
                border-bottom: 3px solid;

                @include media-breakpoint-down(md) {
                    border-bottom: 2px solid;
                }
            }
        }
    }
}

.header--home,
.header--page {
    position: relative;

    & .user {
        position: absolute;
        top: 2rem;
        left: 9rem;
        right: auto;
        font-size: 14px;
        font-weight: 600;
        /*background: url(../../assets/images/user.svg) no-repeat left center;*/
        background-size: 25px;
        padding: 3px 0 3px 33px;

        @include media-breakpoint-down(sm) {
            color: #fff;
            filter: brightness(800%);
        }

        &__name {
            display: inline-block;
            vertical-align: middle;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 90%;

            @media screen and (max-width: 400px) {
                max-width: 100px;
                display: block;
            }

            @media screen and (min-width: 401px) and (max-width: 500px) {
                max-width: 150px;
                display: block;
            }

            @media screen and (min-width: 501px) and (max-width: 600px) {
                max-width: 250px;
                display: block;
            }

            @media screen and (min-width: 601px) and (max-width: 800px) {
                max-width: 180px;
            }
        }

        @media screen and (max-width: 600px) {
            top: 1rem;
        }

        @include media-breakpoint-up(md) {
            top: 1.8rem;
            right: 2rem;
            left: auto;
        }

        a {
            display: inline-block;
            vertical-align: middle;
            margin-left: 0;
            color: #ff0000;
            font-weight: normal;
            text-decoration: none;

            @include media-breakpoint-down(sm) {
                color: #fff;
            }

            @media screen and (min-width: 601px) {
                /*margin-left: .5em; */
            }

            &:hover {
                text-decoration: none;
            }
        }

        .logout {
            display: inline-block;
            padding: 0;
            vertical-align: middle;
            margin-left: 0;
            font-weight: normal;
            border: none;
            background: transparent;
            color: #e40114;

            @media screen and (min-width: 601px) {
                margin-left: 0.5em;
            }
        }
    }

    .user-mobile {
        padding: 0 30px;
    }

    .generic {
        &__notifications {
            font-size: 14px;
            display: inline-block;
            vertical-align: -5px;
            padding-right: 10px;

            line-height: 38px;
            .notifications-bell {
                cursor: pointer;
                color: rgb(228, 1, 20);
                &:hover {
                    color: lighten(rgb(228, 1, 20), 10%);
                }

                .notification-ico {
                    font-size: 25px;
                    &::before {
                        line-height: 37px;
                    }
                }
            }
        }
    }
}

footer {
    .nav {
        margin-top: -0.5rem;
    }

    .nav-link {
        color: #ff0000;
        font-size: 14px;
        font-weight: 600;
        padding: 0.5rem 0.5rem 0.25rem;
        padding: 0.5rem 0.5rem 0.25rem;
        margin: 0 0.75rem;
        border-bottom: 3px solid transparent;
    }

    .nav-link {
        &.active {
            border-bottom: 3px solid;

            @include media-breakpoint-down(md) {
                border-bottom: 2px solid;
            }
        }
    }
}

.navbar-toggler {
    color: white;
}

.navbar-toggler[aria-expanded="true"] {
    .fa-bars {
        display: none;
    }

    .fa-times {
        display: inline-block;
    }
}

.navbar-toggler[aria-expanded="false"] {
    .fa-bars {
        display: inline-block;
    }

    .fa-times {
        display: none;
    }
}

.navbar-nav {
    .nav-item {
        @include media-breakpoint-down(xs) {
            padding: 8px 30px;
        }
    }

    .dropdown-divider {
        @include media-breakpoint-down(xs) {
            border-top: 1px solid rgba(255, 255, 255, 0.4);
        }
    }
}

.error-content {
    display: none;
}

@media screen and (max-width: 373px) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        padding-right: 7px;
    }
}

/* menu už je dost velké, bude na samostatné řádce pořád */
.header--home,
.header--page {
    & .user {
        top: 0.2rem;
    }
}

.header--page .navbar {
    padding-top: 0.8rem;
    min-height: 85px;
}
