$xs: 400px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

.modal-open {
    overflow: hidden;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    max-height: 100%;
    max-width: 100%;
}

.modal-dialog-sm {
    @media (min-width: $sm) {
        min-width: 400px;
    }
}
.modal-dialog-md {
    @media (min-width: $sm) {
        min-width: 400px;
    }
    @media (min-width: $md) {
        min-width: 600px;
    }
}
.modal-dialog-lg {
    @media (min-width: $sm) {
        min-width: 400px;
    }
    @media (min-width: $md) {
        min-width: 600px;
    }
    @media (min-width: $lg) {
        min-width: 800px;
    }
}
.modal-dialog-xl {
    @media (min-width: $sm) {
        min-width: 400px;
    }
    @media (min-width: $md) {
        min-width: 600px;
    }
    @media (min-width: $lg) {
        min-width: 800px;
    }
    @media (min-width: $xl) {
        min-width: 1000px;
    }
}

.modal-dialog {
    .modal-header {
        padding: 0.5rem 1rem;
        align-items: center;
    }
    .modal-title {
        font-weight: bold;
        flex-grow: 1;
    }

    button.window-state {
        border: none;
        background: inherit;
        padding: 4px;
        outline: none;
        opacity: 0.5;
        font-size: 14px;
        width: 24px;

        &:hover {
            opacity: 1;
        }
    }

    button.window-close {
        padding: 4px;
        outline: none;
        font-size: 18px;
        margin-right: -10px;
    }
}

.modal-dialog-max {
    width: 99% !important;
    height: 98% !important;
    max-width: none !important;

    &.modal-dialog-scrollable {
        max-height: none;
    }
}

.modal-dialog-xs-fullscreen {
    @media (max-width: $xs) {
        left: 0;
        top: 0 !important;
        width: 100% !important;
        height: auto !important;
        overflow: scroll;
        align-self: flex-start;

        &.modal-dialog {
            margin: 0 !important;
        }

        .modal-header {
            padding: 0;
            position: relative;
        }

        .modal-title {
            display: none;
        }

        button.close {
            position: absolute;
            top: 3px;
            right: 3px;
            z-index: 1000000;
        }

        .modal-content {
            border-radius: 0;
            border: none;
        }

        .modal-body {
            padding: 0;
            background: #c8c8c8;
        }
    }
}
