@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.records-forms-section {
    background: #fff;
    background-size: cover;
    position: relative;
    padding-top: 50px;

    @include media-breakpoint-up(lg) {
        min-height: 580px;
    }

    @include media-breakpoint-up(md) {
        background: url(../../assets/images/pozadi-medilog.jpg) top center no-repeat;
        background-size: cover;

        &::before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }

    div.info-section {
        position: sticky;
        bottom: 0;
    }

    img.medic {
        max-height: 575px;
        @include media-breakpoint-down(lg) {
            max-width: 480px;
        }
    }
}

.new-record-form {
    border-radius: 0;
    background: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

    @include media-breakpoint-up(sm) {
        border-radius: 5px;
    }

    border: 0;
    padding: 10px;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        padding: 15px 20px;
    }

    label {
        font-size: 0.8rem;
    }

    a {
        text-decoration: underline;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .record-edit__param-title {
        text-transform: uppercase;
        font-size: 0.8rem;
    }

    &__body {
        > .form-group {
            //border-bottom: 1px solid rgb(224, 224, 224);
            &:last-child {
                border-bottom: 0;
            }
        }
    }

    &__close,
    &__min {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: url(../../assets/images/close.svg) no-repeat center;
        background-size: 14px;
        width: 30px;
        height: 30px;
        text-indent: -20rem;
        overflow: hidden;
        opacity: 0.6;
    }

    &__min {
        background: url(../../assets/images/min.svg) no-repeat center;
        background-size: 14px;
    }

    &-body {
        &--success {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: var(--teal);
            z-index: 15;
            transition: 0.5s;
            transform: translateY(100%);
        }
    }
}
