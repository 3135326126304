$xs: 400px;
$lg: 992px;
$xl: 1200px;

.title {
    font-size: 28px;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    color: #4e4e4e;
}

.text {
    text-align: justify;

    @media (max-width: $xs) {
        padding: 15px !important;
    }

    @media (min-width: $lg) {
        column-count: 2;
        column-gap: 20px;
    }

    @media (min-width: $xl) {
        column-gap: 40px;
    }
}
