$btn-color: #343a40;
$smileSize: 40px;

.param-text {
    font-size: 13px;
}

.param-info {
    display: table;
    table-layout: fixed;
    border-spacing: 0px 10px;
    border-collapse: separate;

    &__row {
        cursor: pointer;
        display: table-row;

        &:hover &__btn {
            color: #fff;
            background-color: $btn-color;
            border-color: $btn-color;
        }

        &__value {
            display: table-cell;
            border-right: 10px solid transparent;
            font-size: 14px;
            vertical-align: text-top;
        }

        &__btn {
            width: 100%;
            border-radius: 5px;
            padding: 10px;
            border: 1px solid black;
            text-align: center;
            font-weight: 600;
            font-size: 13px;
        }

        &__desc {
            display: table-cell;
            font-size: 13px;
            vertical-align: middle;
        }
    }
}

.mood-info {
    background: url("./assets/images/slider/smile-sprite.png");
    width: $smileSize;
    height: $smileSize;
    background-repeat: no-repeat;
    background-position-x: 0 left;
    border: none;
    padding: 0;

    @for $i from 0 through 4 {
        &.smile#{5 - $i} {
            background-position-y: -$i * $smileSize;
        }
    }
}
