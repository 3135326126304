/**
Ion.RangeSlider, 2.3.1
© Denis Ineshin, 2010 - 2019, IonDen.com
Build date: 2019-12-19 16:51:02
*/
.irs {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    margin-bottom: 1rem;
}
.irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
}

//mf
.irs-line {
    overflow: visible;
}
.irs-line::after {
    position: absolute;
    width: 100%;
    display: block;
    padding-bottom: 36px;
    z-index: 1;
    opacity: 0;
    content: " ";
}

.irs-bar {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
}
.irs-shadow {
    position: absolute;
    display: none;
    left: 0;
    width: 0;
}
.irs-handle {
    position: absolute;
    display: block;
    box-sizing: border-box;
    cursor: default;
    z-index: 1;
}

.irs-handle.type_last {
    z-index: 2;
}
.irs-min,
.irs-max {
    position: absolute;
    display: block;
    cursor: default;
}
.irs-min {
    left: 0;
}
.irs-max {
    right: 0;
}
.irs-from,
.irs-to,
.irs-single {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
}
.irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}
.irs-with-grid .irs-grid {
    display: block;
}
.irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: #000;
}
.irs-grid-pol.small {
    height: 4px;
    display: none;
}
.irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 13px;
    line-height: 9px;
    padding: 0 3px;
    color: #5e5e5e;
}
.irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: -1%;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}
.lt-ie9 .irs-disable-mask {
    background: #000;
    filter: alpha(opacity=0);
    cursor: not-allowed;
}
.irs-disabled {
    opacity: 0.4;
}
.irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
}
.irs--flat {
    height: 40px;
}
.irs--flat.irs-with-grid {
    height: 60px;
}
.irs--flat .irs-line {
    top: 25px;
    height: 12px;
    background-color: #e1e4e9;
    border-radius: 4px;
}
.irs--flat .irs-bar {
    top: 25px;
    height: 12px;
    background-color: #ed5565;
}
.irs--flat .irs-bar--single {
    border-radius: 4px 0 0 4px;
}
.irs--flat .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: #e1e4e9;
}
.irs--flat .irs-handle {
    top: 22px;
    width: 16px;
    height: 18px;
    background-color: transparent;
}
.irs--flat .irs-handle > i:first-child {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: #da4453;
}
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
    background-color: #a43540;
}
.irs--flat .irs-min,
.irs--flat .irs-max {
    top: 0;
    padding: 1px 3px;
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    background-color: #e1e4e9;
    border-radius: 4px;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
    color: white;
    font-size: 18px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #ed5565;
    border-radius: 4px;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #ed5565;
}
.irs--flat .irs-grid-pol {
    background-color: #e1e4e9;
}
.irs--flat .irs-grid-text {
    color: #999;
}

.irs--round {
    height: 50px;
}
.irs--round.irs-with-grid {
    height: 68px;
}
.irs--round .irs-line {
    top: 36px;
    height: 7px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #bcbcbc;
    margin: 0 5px;
}
.irs--round .irs-bar {
    top: 36px;
    height: 7px;
    background-color: #db3b00;
    left: 5px !important;
}
.irs--round .irs-bar--single {
    border-radius: 4px 0 0 4px;
}
.irs--round .irs-shadow {
    height: 4px;
    bottom: 21px;
    background-color: rgba(222, 228, 236, 0.5);
}
.irs--round .irs-handle {
    top: 24px;
    width: 24px;
    height: 24px;
    border: 4px solid #db3b00;
    background-color: white;
    border-radius: 24px 24px 0 24px;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2);
    transform: rotate(45deg);
}

.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
    background-color: #d3eaff;
}

.irs--round .irs-min,
.irs--round .irs-max {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}
.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
    font-size: 18px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: transparent;
    color: #ff0000;
    font-weight: 700;
    border-radius: 4px;
}
.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: transparent;
}
.irs--round .irs-grid {
    height: 25px;
}
.irs--round .irs-grid-pol {
    background-color: #dedede;
}
.irs--round .irs-grid-text {
    color: rgb(221, 221, 221);
    font-size: 13px;
    font-weight: 600;
}

/* Virtualní zvětšený jezdec pro dobrý touch */
.irs--round {
    .irs-handle::before {
        content: " ";
        display: block;
        padding: 40px;
        margin: -30px 0 0 -30px;
        border-radius: 40px;
    }

    &.irs-default-value {
        $defaultValueColor: #a3a3a3;

        .irs-single {
            color: $defaultValueColor;
            font-size: 18px;
        }

        .irs-handle {
            border-color: $defaultValueColor;
        }
    }

    &.irs-no-value {
        $noValueColor: #ddd;

        .irs-single {
            color: $noValueColor !important;
        }

        .irs-bar {
            background: $noValueColor !important;
        }

        .irs-handle {
            border-color: $noValueColor !important;
        }
    }
}
