body {
    font-family: "Montserrat", sans-serif;
}

p {
    font-size: calculateRem(14px);
}

li {
    font-size: calculateRem(14px);
}

.t-1 {
    font-size: calculateRem(13px) !important;
}

.t-2 {
    font-size: calculateRem(14px) !important;
}

.t-5 {
    font-size: calculateRem(26px) !important;

    @include media-breakpoint-down(md) {
        font-size: calculateRem(18px) !important;
    }
}

.t-6 {
    font-size: calculateRem(48px) !important;

    @include media-breakpoint-down(md) {
        font-size: calculateRem(38px) !important;
    }
}

.font-weight-semibold {
    font-weight: 600;
}

.table {
    &-sm {
        font-size: 12px;
    }

    th {
        font-weight: 600;
    }

    tr.revoked-sharing {
        color: #cccccc;
    }
}

.pagination {
    &-sm {
        .page-link {
            font-size: 13px;
        }
    }
}

.text-black-25 {
    color: rgb(163, 163, 163);
}

.color-grey1 {
    color: #777777;
}

.t-center {
    justify-content: center;
}

.t-uppercase {
    text-transform: uppercase;
}

.bg-silver {
    background-color: rgba(0, 0, 0, 0.1);
}

#sharing-form-section,
#connections-form-section {
    background: #fff;
    background-size: cover;
    position: relative;

    @include media-breakpoint-up(md) {
        background: url(../../assets/images/frontpage-cover.jpg) top center no-repeat;
        background-size: cover;

        &::before {
            content: "";
            position: absolute;
            z-index: 0;
            background-color: rgba(0, 0, 0, 0.5);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}

.empty-record {
    font-weight: 600;

    &::before {
        content: " ";
        width: 80px;
        height: 80px;
        position: absolute;
        bottom: 20px;
        right: 102px;
        background: url("../../assets/images/arrow-by-hand.png") left top no-repeat;
        background-size: contain;
    }
}

.fa-times--mw {
    content: "";
    background: url(../../assets/images/close.svg) no-repeat center;
    background-size: 14px;
    width: 28px;
    height: 28px;
    &::before {
        content: none;
    }
}

.ul-li {
    &--greenheart ul {
        padding: 15px 0;
        margin: 0;

        & li {
            list-style: none;
            padding-left: 0;
            text-indent: -30px;
            margin-bottom: 10px;
            margin-left: 30px;

            &::before {
                content: "";
                display: inline-block;
                height: 20px;
                width: 30px;
                background-image: url(../../assets/images/passionn.svg);
                background-repeat: no-repeat;
                background-position: 0px 3px;
                background-size: 17px;
                vertical-align: top;
            }
        }
    }
}
