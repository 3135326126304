$primary-fg-color: #fff;
$primary-bg-color: #d74c87;
$primary-bg-hover-color: lighten($primary-bg-color, 10%);

$secondary-bg-color: #df5200;

$text-color: #24373d;
$body-bg-color: white;

$header-text-color: $primary-fg-color;
$header-bg-color: rgba($text-color, 0.6);
$footer-bg-color: $text-color;

$validation-color: #f00;
$validation-hover-color: lighten($validation-color, 20%);

$radius: 5px;
$logo-width: 40px;

$unread-color: #ff0000;
