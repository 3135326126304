.overlay-portal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0004;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}
